<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios";
import { authHeader } from "../../../helpers/auth-header";

import ConfirmationModal from "@/components/modals/confirmation-modal";
import MessageModal from "@/components/modals/message-modal";

import InputGroup from "@/components/forms/input-group";
import SelectGroup from "@/components/forms/select-group";

import SaveButton from "@/components/buttons/save-button";
import CancelButton from "@/components/buttons/cancel-button";

/**
 * Orders Component
 */
export default {
    components: {
        Layout,
        PageHeader,
        ConfirmationModal,
        MessageModal,
        InputGroup,
        SelectGroup,
        SaveButton,
        CancelButton
    },
    data() {
        const id = this.$route.params.id;
        const modeName = id === 'add' ? 'Add' : 'Edit';

        return {
            id: id,
            title: `${modeName} Service`,
            modeName,
            items: [
                {
                    text: "Service"
                },
                {
                    text: modeName,
                    active: true,
                },
            ],
            data: {
                name: '',
                status: 1,
                rate_settings: [
                    {
                        studentGradeCategory: '',
                        tutorType: '',
                        rate: ''
                    }
                ],
            },
            statusOptions: [
                { name: 'Active', value: 1 },
                { name: 'Inactive', value: 0 },
            ],
            durationOptions: [],
            selectedDurations: [],
            frequencyOptions: [],
            selectedFrequencies: [],
            tutorOptions: [],
            selectedTutors: [],
            categoryOptions: [],
            errors: {},
        };
    },
    async mounted() {
        this.retrieveDurations().then((durations) => {
            this.durationOptions = durations.map((duration) => ({
                name: duration.duration,
                value: duration.id,
            }));
        });

        this.retrieveFrequencies().then((frequencies) => {
            this.frequencyOptions = frequencies.map((frequency) => ({
                name: frequency.frequency,
                value: frequency.id,
            }));
        });

        this.retrieveTutors().then((tutors) => {
            this.tutorOptions = tutors.map((tutor) => ({
                name: tutor.name,
                value: tutor.id,
            }));
        });

        this.retrieveCategories().then((categories) => {
            this.categoryOptions = categories.map((category) => ({
                name: category.name,
                value: category.id,
            }));
        });

        if (this.modeName !== 'Add') {
            const result = await axios.get(`${process.env.VUE_APP_APIURL}/field_management/service/${this.id}`, {
                headers: authHeader(),
            });

            if (result && result.data['code'] === 'success') {
                const data = result.data['data']['service'];
                for (const [key, item] of Object.entries(data)) {
                    if (key in this.data) {
                        this.data[key] = item;
                    } else if (key === 'durations') {
                        this.selectedDurations = JSON.parse(item) ?? [];
                    } else if (key === 'frequency') {
                        this.selectedFrequencies = JSON.parse(item) ?? [];
                    } else if (key === 'teacher_type') {
                        this.selectedTutors = JSON.parse(item) ?? [];
                    }
                }

                if (typeof this.data.rate_settings === 'string') {
                    try {
                        this.data.rate_settings = JSON.parse(this.data.rate_settings);
                    } catch (error) {
                        console.error("Error parsing rate_settings JSON:", error);
                        this.data.rate_settings = [];
                    }
                }
console.log('aaa', data);
            }
        }

        if (!this.data.rate_settings || this.data.rate_settings.length == 0) {
            this.data.rate_settings = [{
                studentGradeCategory: '',
                tutorType: '',
                rate: ''
            }];
        }
    },
    methods: {
        addRow() {
            this.data.rate_settings.push({
                studentGradeCategory: '',
                tutorType: '',
                rate: ''
            });
        },
        removeRow(index) {
            if (this.data.rate_settings && this.data.rate_settings.length > 1) {
                this.data.rate_settings.splice(index, 1);
            }
            // Ensure at least one row exists
            if (this.data.rate_settings.length == 0) {
                this.data.rate_settings = [{
                    studentGradeCategory: '',
                    tutorType: '',
                    rate: ''
                }];
            }
        },
        toggleDurationCheckbox(durationId) {
            const index = this.selectedDurations.indexOf(durationId);
            if (index !== -1) {
                this.selectedDurations.splice(index, 1);
            } else {
                this.selectedDurations.push(durationId);
            }
        },
        toggleFrequencyCheckbox(frequencyId) {
            const index = this.selectedFrequencies.indexOf(frequencyId);
            if (index !== -1) {
                this.selectedFrequencies.splice(index, 1);
            } else {
                this.selectedFrequencies.push(frequencyId);
            }
        },
        toggleTutorCheckbox(tutorId) {
            const index = this.selectedTutors.indexOf(tutorId);
            if (index !== -1) {
                this.selectedTutors.splice(index, 1);
            } else {
                this.selectedTutors.push(tutorId);
            }
        },
        async retrieveDurations() {
            this.loading = true;
            let durations = [];

            try {
                const item = await axios.get(`${process.env.VUE_APP_APIURL}/field_management/subject_duration`, {
                    headers: authHeader(),
                });

                const result = await item.data;
                console.log('result', result)
                if (result['code'] === 'success') {
                    const durationDetails = result['data'].subject_durations;
                    durations = durationDetails.filter(duration => duration.status == 1);
                }
            } catch (e) {
                console.error(e);
            }

            this.loading = false;
            return durations;
        },

        async retrieveFrequencies() {
            this.loading = true;
            let frequencies = [];

            try {
                const item = await axios.get(`${process.env.VUE_APP_APIURL}/field_management/subject_frequency`, {
                    headers: authHeader(),
                });

                const result = await item.data;
                if (result['code'] === 'success') {
                    const frequencyDetails = result['data'].subject_frequencys;
                    frequencies = frequencyDetails.filter(frequency => frequency.status == 1);
                }
            } catch (e) {
                console.error(e);
            }

            this.loading = false;
            return frequencies;
        },

        async retrieveTutors() {
            this.loading = true;
            let tutors = [];

            try {
                const item = await axios.get(`${process.env.VUE_APP_APIURL}/field_management/tutor_type`, {
                    headers: authHeader(),
                });

                const result = await item.data;
                if (result['code'] === 'success') {
                    const tutorDetails = result['data'].tutor_types;
                    tutors = tutorDetails.filter(type => type.status == 1);
                }
            } catch (e) {
                console.error(e);
            }

            this.loading = false;
            return tutors;
        },

        async retrieveCategories() {
            this.loading = true;
            let categories = [];

            try {
                const item = await axios.get(`${process.env.VUE_APP_APIURL}/field_management/student_grade_category`, {
                    headers: authHeader(),
                });

                const result = await item.data;
                if (result['code'] === 'success') {
                    categories = result['data'].student_grade_categories;
                    console.log('this.categoryOptions', this.categoryOptions)
                }
            } catch (e) {
                console.error(e);
            }

            this.loading = false;
            return categories;
        },

        async save() {
            this.errors = {};
            let result;
            if (this.modeName === 'Add') {
                const requestData = this.data;
                result = await axios.post(`${process.env.VUE_APP_APIURL}/field_management/service`, requestData, {
                    headers: authHeader(),
                });
            } else {
                const requestData = this.data;
                requestData.durations = this.selectedDurations;
                requestData.frequency = this.selectedFrequencies;
                requestData.teacher_type = this.selectedTutors;

                result = await axios.put(`${process.env.VUE_APP_APIURL}/field_management/service/${this.id}`, requestData, {
                    headers: authHeader(),
                });
            }

            if (result) {
                if (result.data['code'] === 'success') {
                    if (this.modeName === 'Add') {
                        this.$router.back();
                    } else {
                        this.$refs.messageModal.showModal('Your record has been updated successfully');
                    }
                } else if (result.data['code'] === 'invalid_field') {
                    this.errors = result.data['errors'];
                }
            }
        },
        cancel() {
            this.$router.push({ name: "Service" });
        }
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <ConfirmationModal ref="confirmationModal" />
        <MessageModal ref="messageModal" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body p-4">
                        <div class="row mt-2 mb-5">
                            <div class="col-12 col-md-4 mb-2">
                                <InputGroup type="text" id="name" displayName="Service" v-model="data.name" :error="errors.name" />
                            </div>
                            <div class="col-12 col-md-4 mb-2">
                                <SelectGroup id="status" displayName="Status" v-model="data.status" :error="errors.status" :options="statusOptions" />
                            </div>

                            <div class="col-12">
                                <label class="label">Duration</label>
                            </div>
                            <div v-for="duration in durationOptions" :key="duration.value" class="col-12 col-md-3">
                                <input 
                                type="checkbox" 
                                :id="`duration_${duration.value}`" 
                                :value="duration.value" 
                                :checked="selectedDurations ? selectedDurations.includes(duration.value) : false" 
                                @change="toggleDurationCheckbox(duration.value)">
                                &nbsp;<label :for="`duration_${duration.value}`">{{ duration.name }} Minutes</label>
                            </div>

                            <div class="col-12 mt-3">
                                <label class="label">Frequency</label>
                            </div>
                            <div v-for="frequency in frequencyOptions" :key="`frequency_${frequency.value}`" class="col-12 col-md-3">
                                <input type="checkbox" 
                                :id="`frequency_${frequency.value}`" 
                                :value="frequency.value" 
                                :checked="selectedFrequencies ? selectedFrequencies.includes(frequency.value) : false" 
                                @change="toggleFrequencyCheckbox(frequency.value)">
                                &nbsp;<label :for="`frequency_${frequency.value}`">{{ parseInt(frequency.name) > 1 ? `${frequency.name} lessons` : `${frequency.name} lesson` }} per week</label>
                            </div>

                            <div class="col-12 mt-3">
                                <label class="label">Type of tutor / professionals / specialists</label>
                            </div>
                            <div v-for="tutor in tutorOptions" :key="`tutor_${tutor.value}`" class="col-12 col-md-3">
                                <input type="checkbox" 
                                :id="`tutor_${tutor.value}`" 
                                :value="tutor.value" 
                                :checked="selectedTutors ? selectedTutors.includes(tutor.value) : false" 
                                @change="toggleTutorCheckbox(tutor.value)">
                                &nbsp;<label :for="`tutor_${tutor.value}`">{{ tutor.name }}</label>
                            </div>
                        </div>

                        <p class="label">Rate Settings</p>
                        <div class="row" v-for="(row, index) in data.rate_settings" :key="index">
                            <div class="col-6 col-md-3">
                                <SelectGroup 
                                    :id="'student_grade_category_' + index" 
                                    displayName="Student Grade Category" 
                                    v-model="row.studentGradeCategory" 
                                    :options="categoryOptions" 
                                    />
                            </div>
                            <div class="col-6 col-md-3">
                                <SelectGroup 
                                    :id="'tutor_type_' + index" 
                                    displayName="Service Provider" 
                                    v-model="row.tutorType" 
                                    :options="tutorOptions" 
                                    />
                            </div>
                            <div class="col-6 col-md-3">
                                <InputGroup 
                                :id="'rate_' + index" 
                                displayName="Rate" 
                                v-model="row.rate" 
                                />
                            </div>
                            <div class="col-6 col-md-3 d-flex align-items-center mt-2">
                                <!-- Plus Button -->
                                <button v-if="index == 0" @click="addRow" class="btn btn-success">+</button>
                                <!-- Remove Button -->
                                <button v-if="index != 0" @click="removeRow(index)" class="btn btn-danger">-</button>
                            </div>
                        </div>

                        <div class="divider"></div>

                        <div class="row mt-2" style="float: right;">
                            <div class="col-12">
                                <CancelButton @click="cancel"></CancelButton>
                                <SaveButton :isSubmit="true" :text="'Save'" @click="save" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>